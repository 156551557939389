<template>
	<div>
		<div v-for="(feature, index) in features" :key="index">
			<div v-if="feature.list.length">
				<p class="feature-title" :style="{ color: feature.color }">
					{{ feature.title }}
				</p>
				<div v-for="(item, index) in feature.list" :key="index">
					<a-icon type="check" :style="{ color: feature.color }" />
					<span v-html="item" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		package: {
			type: String,
			required: true,
		},
		packageCode: {
			type: String,
			required: true,
		},
	},

	computed: {
		admin() {
			if (["PA-PRE-LNCH-LR", "PA-PRE-LNCH-HR"].includes(this.packageCode)) {
				return [
					"Unlimited File Storage",
					"Unlimited Team Members",
					"Unlimited Contacts",
					"Team Configurator",
				];
			}
			return [
				"Unlimited File Storage",
				"Unlimited Team Members",
				"Unlimited Contacts",
				"Team Configurator",
				"Reports & Analytics",
			];
		},
		project() {
			if (
				[
					"Community Low Rise Premium",
					"Condo High Rise Premium",
				].includes(this.package)
			) {
				return [
					"Content Asset Management",
					"Amenity Map Configurator",
					"Form & Survey Configurator",
					"Contract Configurator",
				];
			} else if (
				[
					"Community Low Rise Essentials",
					"Condo High Rise Essentials",
				].includes(this.package)
			) {
				return [
					"Content Asset Management",
					"Form & Survey Configurator",
					"Contract Configurator",
				];
			} else if (["Group Low Rise", "Corporate"].includes(this.package)) {
				return [
					"Content Asset Management",
					"Amenity Map Configurator",
					"Form & Survey Configurator",
				];
			} else if (this.package === "Community Low Rise Supplementary") {
				return [
					"Content Asset Management",
					"<span style='color: #9693E7'>Amenity Map Configurator</span>",
					"<span style='color: #9693E7'>Form & Survey Configurator</span>",
					"Contract Configurator",
				];
			} else if (this.package === "Maintenance") {
				return [
					"Content Asset Management"
				];
			} else if (this.packageCode === "PA-PRE-LNCH-LR") {
				return [
					"Content Asset Management",
					"Amenity Map Configurator",
					"Form & Survey Configurator",
					"Contract Configurator",
				];
			} else if (this.packageCode === "PA-PRE-LNCH-HR") {
				return [
					"Content Asset Management",
					"Form & Survey Configurator",
					"Contract Configurator",
				];
			} else return [];
		},
		product() {
			if (
				[
					"Community Low Rise Premium",
					"Community Low Rise Supplementary",
				].includes(this.package)
			) {
				return [
					"Home Model Configurator",
					"<span style='color: #9693E7'>Features Configurator</span>",
					"Site Plan Configurator",
					"Master Plan Configurator",
				];
			} else if (this.package === "Community Low Rise Essentials") {
				return [
					"Home Model Configurator",
					"Site Plan Configurator",
					"<span style='color: #9693E7'>Master Plan Configurator</span>",
				];
			} else if (this.package === "Condo High Rise Premium") {
				return [
					"<span style='color: #9693E7'>Features Configurator</span>",
					"Condo Unit Configurator",
					"<span style='color: #9693E7'>Condo Grid Configurator</span>",
				];
			} else if (this.package === "Condo High Rise Essentials") {
				return ["Condo Unit Configurator"];
			} else if (this.package === "Group Low Rise") {
				return ["Site Plan Configurator", "Master Plan Configurator"];
			} else if (this.packageCode === "PA-PRE-LNCH-LR") {
				return ["Home Model Configurator", "Features Configurator", "Site Plan Configurator", "Master Plan Configurator"];
			} else if (this.packageCode === "PA-PRE-LNCH-HR") {
				return ["Condo Unit Configurator"];
			} else return [];
		},
		marketing() {
			if (
				[
					"Community Low Rise Premium",
					"Condo High Rise Premium",
				].includes(this.package)
			) {
				return [
					"Landing Page Builder",
					"<span style='color: #2C94C8'><strong>5</strong> Landing Pages Supported</span>",
					"Website Builder",
					"<span style='color: #2C94C8'>Touchscreen Builder</span>",
					"Broadcast Email Builder",
					"<span style='color: #2C94C8'><strong> 25,000</strong> Email Sends Per Month</span>",
					"Publishing Manager",
				];
			} else if (
				[
					"Community Low Rise Essentials",
					"Condo High Rise Essentials",
				].includes(this.package)
			) {
				return [
					"Landing Page Builder",
					"<span style='color: #2C94C8'><strong>3</strong> Landing Pages Supported</span>",
					"Website Builder",
					"Broadcast Email Builder",
					"<span style='color: #2C94C8'><strong>15,000</strong> Email Sends Per Month</span>",
					"Publishing Manager",
				];
			} else if (this.package === "Community Low Rise Supplementary") {
				return [
					"<span style='color: #2C94C8'>Touchscreen Builder</span>",
					"Broadcast Email Builder",
					"<span style='color: #2C94C8'><strong>10,000</strong> Email Sends Per Month</span>",
					"Publishing Manager",
				];
			} else if (this.package === "Group Low Rise") {
				return [
					"Landing Page Builder",
					"<span style='color: #2C94C8'><strong>5</strong> Landing Pages Supported</span>",
					"Website Builder",
					"<span style='color: #2C94C8'>Touchscreen Builder</span>",
					"Broadcast Email Builder",
					"<span style='color: #2C94C8'><strong> 50,000</strong> Email Sends Per Month</span>",
					"Publishing Manager",
				];
			} else if (this.package === "Corporate") {
				return [
					"Landing Page Builder",
					"<span style='color: #2C94C8'><strong>Unlimited</strong> Landing Pages Supported</span>",
					"Website Builder",
					"Broadcast Email Builder",
					"<span style='color: #2C94C8'><strong> 50,000</strong> Email Sends Per Month</span>",
					"Publishing Manager",
				];
			} else if (["PA-PRE-LNCH-LR", "PA-PRE-LNCH-HR"].includes(this.packageCode)) {
				return [
					"Landing Page Builder",
					"Website Builder",
					"Publishing Manager",
				];
			} else return [];
		},
		customer() {
			if (["Group Low Rise", "Corporate"].includes(this.package)) {
				return ["Customer Relationship Management"];
			} else if (["Condo High Rise Premium"].includes(this.package)) {
				return [
					"Customer Relationship Management",
					"Transaction Management",
					"Inventory & Price Management",
					"Worksheet Management",
				];
			} else if (this.package === "Maintenance") {
				return [
					"Customer Relationship Management",
					"Transaction Management",
				];
			} else if (["PA-PRE-LNCH-LR", "PA-PRE-LNCH-HR"].includes(this.packageCode)) {
				return [
					"Customer Relationship Management",
				];
			} else {
				return [
					"Customer Relationship Management",
					"Transaction Management",
					"Inventory & Price Management"
				]
			}
		},
		support() {
			return ["Ticket Support"];
		},
		features() {
			return [
				{
					title: "ADMIN & TEAM MANAGEMENT",
					color: "#FF647C",
					list: this.admin,
				},
				{
					title: "PROJECT CONFIGURATION",
					color: "#FFCF5C",
					list: this.project,
				},
				{
					title: "PRODUCT MANAGEMENT",
					color: "#9693E7",
					list: this.product,
				},
				{
					title: "MARKETING & COMMUNICATIONS MANAGEMENT",
					color: "#2C94C8",
					list: this.marketing,
				},
				{
					title: ["Group Low Rise", "Corporate", "Pre Launch"].includes(this.package) ? "CUSTOMER MANAGEMENT" : "CUSTOMER & TRANSACTION MANAGEMENT",
					color: "#00C48C",
					list: this.customer,
				},
				{
					title: "SUPPORT",
					color: "#40454C",
					list: this.support,
				},
			];
		},
	},
};
</script>

<style lang="scss" scoped>
.feature-title {
	font-size: 14px;
	font-weight: bold;
	color: var(--orange);
	text-align: left;
	margin-top: 15px;
}

.ant-list-item {
	font-size: 12px;
	padding: 0 0 0 0;
	border-bottom: 0px !important;
}

div>span {
	font-size: 12px;
}

div>i {
	color: var(--orange);
	font-size: 12px;
	margin-right: 5px;
}
</style>
